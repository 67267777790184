/**
 * @轮播图数据
 */
export const state01 = [
    {
        url:require("@/assets/image/index/10101.png"),
        link:''
    },{
        url:require("@/assets/image/index/10102.png"),
        link:''
    }
]

/**
 * @产品展示数据
 */
export const state02 = [
        {// 第1页
            t:[  // 上面四个
                {
                url:require("@/assets/image/index/1A01.jpg"),
                t1:"售货机 AI识别 先取后付",
                t2:"大型触屏 超大组合 智能零售",
                d:{
                    url:require("@/assets/image/index/1A01.jpg"),
                    name:'互隆智能触屏大制冷自助售卖机',
                    tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                    pp:'互隆',
                    cc:'尺寸',
                    gn:'功能',
                    zl:'重量',
                    gl:'功率',
                    qhk:'取货口',
                    fbl:'分辨率',
                    zlfw:'制冷范围',
                    jg:'面议',
                    zffs:'？'
                }
                },
                {
                url:require("@/assets/image/index/1A02.jpg"),
                t1:"售货机 小型 桌面款",
                t2:"大型触屏 超大组合 智能零售"
                },
                {
                url:require("@/assets/image/index/1A03.png"),
                t1:"自助售票机 体福共用",
                t2:"大型触屏 超大组合 智能零售"
                },
                {
                url:require("@/assets/image/index/10201.jpg"),
                t1:"互隆设备大制冷售卖机4",
                t2:"大型触屏 超大组合 智能零售"
                }
            ],
            b:[  // 下面四个
                {
                url:require("@/assets/image/index/4A01.png"),
                t1:"智能格子柜 可扩展",
                t2:"大型触屏 超大组合 智能零售",
                d:{
                    url:require("@/assets/image/index/4A01.png"),
                    name:'互隆智能触屏大制冷自助售卖机',
                    tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                    pp:'互隆',
                    cc:'尺寸',
                    gn:'功能',
                    zl:'重量',
                    gl:'功率',
                    qhk:'取货口',
                    fbl:'分辨率',
                    zlfw:'制冷范围',
                    jg:'面议',
                    zffs:'？'
                }
                },
                {
                url:require("@/assets/image/index/4A02.png"),
                t1:"智能存储柜 35格",
                t2:"大型触屏 超大组合 智能零售"
                },
                {
                url:require("@/assets/image/index/10201.jpg"),
                t1:"互隆设备大制冷售卖机7",
                t2:"大型触屏 超大组合 智能零售"
                },
                {
                url:require("@/assets/image/index/10201.jpg"),
                t1:"互隆设备大制冷售卖机8",
                t2:"大型触屏 超大组合 智能零售"
                }
            ]
        },
        {// 第2页
            t:[  // 上面四个
                {
                url:require("@/assets/image/index/4A01.png"),
                t1:"智能格子柜 可扩展",
                t2:"大型触屏 超大组合 智能零售",
                d:{
                    url:require("@/assets/image/index/10201.jpg"),
                    name:'互隆智能触屏大制冷自助售卖机',
                    tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                    pp:'互隆',
                    cc:'尺寸',
                    gn:'功能',
                    zl:'重量',
                    gl:'功率',
                    qhk:'取货口',
                    fbl:'分辨率',
                    zlfw:'制冷范围',
                    zffs:'？'
                }
                },
                {
                url:require("@/assets/image/index/4A02.png"),
                t1:"智能存储柜 35格",
                t2:"大型触屏 超大组合 智能零售"
                },
                {
                url:require("@/assets/image/index/2A02.png"),
                t1:"壁挂零售机 可落地",
                t2:"大型触屏 超大组合 智能零售"
                },
                {
                url:require("@/assets/image/index/7A01.jpg"),
                t1:"电动牙刷",
                t2:"大型触屏 超大组合 智能零售"
                }
            ],
            b:[  // 下面四个
                {
                url:require("@/assets/image/index/10201.jpg"),
                t1:"互隆设备大制冷售卖机",
                t2:"大型触屏 超大组合 智能零售",
                d:{
                    url:require("@/assets/image/index/10201.jpg"),
                    name:'互隆智能触屏大制冷自助售卖机',
                    tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                    pp:'互隆',
                    cc:'尺寸',
                    gn:'功能',
                    zl:'重量',
                    gl:'功率',
                    qhk:'取货口',
                    fbl:'分辨率',
                    zlfw:'制冷范围',
                    zffs:'？'
                }
                },
                {
                url:require("@/assets/image/index/10201.jpg"),
                t1:"互隆设备大制冷售卖机",
                t2:"大型触屏 超大组合 智能零售"
                },
                {
                url:require("@/assets/image/index/10201.jpg"),
                t1:"互隆设备大制冷售卖机",
                t2:"大型触屏 超大组合 智能零售"
                },
                {
                url:require("@/assets/image/index/10201.jpg"),
                t1:"互隆设备大制冷售卖机",
                t2:"大型触屏 超大组合 智能零售"
                }
            ]
        }
]

export const state03 = [
    {
        src: require("/src/assets/image/index/互隆设备-主页规划_15.jpg"),
        title: "武汉市第一医院设置智能取餐柜",
      },
      {
        src: require("/src/assets/image/index/互隆设备-主页规划_17.jpg"),
        title: "厦门市软件园二期",
      },
      {
        src: require("/src/assets/image/index/互隆设备-主页规划_19.jpg"),
        title: "厦门市信义国际中心",
      },
      {
        src: require("/src/assets/image/index/互隆设备-主页规划_25.jpg"),
        title: "福建医科大学附属第二医院",
      },
      {
        src: require("/src/assets/image/index/互隆设备-主页规划_27.jpg"),
        title: "厦门大学附属中山医院",
      },
      {
        src: require("/src/assets/image/index/互隆设备-主页规划_29.jpg"),
        title: "厦门医院研究所",
      }
]
/**
 * @产品展示数据
 */
 export const state04 = [
    {// 第1页
        t:[  // 上面四个
            {
            url:require("@/assets/image/index/1A01.jpg"),
            t1:"售货机 先取后付",
            t2:"大型触屏 超大组合 智能零售",
            d:{
                url:require("@/assets/image/index/1A01.jpg"),
                name:'互隆智能触屏大制冷自助售卖机',
                tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                pp:'互隆',
                cc:'尺寸',
                gn:'功能',
                zl:'重量',
                gl:'功率',
                qhk:'取货口',
                fbl:'分辨率',
                zlfw:'制冷范围',
                jg:'面议',
                zffs:'？'
            }
            },
            {
            url:require("@/assets/image/index/1A02.jpg"),
            t1:"售货机 小型桌面框",
            t2:"大型触屏 超大组合 智能零售",
            d:{
                url:require("@/assets/image/index/1A02.jpg"),
                name:'互隆智能触屏大制冷自助售卖机',
                tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                pp:'互隆',
                cc:'尺寸',
                gn:'功能',
                zl:'重量',
                gl:'功率',
                qhk:'取货口',
                fbl:'分辨率',
                zlfw:'制冷范围',
                jg:'面议',
                zffs:'？'
            }
            },
            {
            url:require("@/assets/image/index/1A03.png"),
            t1:"自助售票机 体福共用",
            t2:"大型触屏 超大组合 智能零售",
            d:{
                url:require("@/assets/image/index/1A03.png"),
                name:'互隆智能触屏大制冷自助售卖机',
                tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                pp:'互隆',
                cc:'尺寸',
                gn:'功能',
                zl:'重量',
                gl:'功率',
                qhk:'取货口',
                fbl:'分辨率',
                zlfw:'制冷范围',
                jg:'面议',
                zffs:'？'
            }
            },
            {
            url:require("@/assets/image/index/10201.jpg"),
            t1:"互隆设备小制冷售卖机",
            t2:"大型触屏 超大组合 智能零售",
            d:{
                url:require("@/assets/image/index/10201.jpg"),
                name:'互隆智能触屏大制冷自助售卖机',
                tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                pp:'互隆',
                cc:'尺寸',
                gn:'功能',
                zl:'重量',
                gl:'功率',
                qhk:'取货口',
                fbl:'分辨率',
                zlfw:'制冷范围',
                jg:'面议',
                zffs:'？'
            }
            }
        ],
        b:[  // 下面四个
            {
            url:require("@/assets/image/index/10201.jpg"),
            t1:"互隆设备小制冷售卖机",
            t2:"大型触屏 超大组合 智能零售",
            d:{
                url:require("@/assets/image/index/10201.jpg"),
                name:'互隆智能触屏大制冷自助售卖机',
                tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                pp:'互隆',
                cc:'尺寸',
                gn:'功能',
                zl:'重量',
                gl:'功率',
                qhk:'取货口',
                fbl:'分辨率',
                zlfw:'制冷范围',
                jg:'面议',
                zffs:'？'
            }
            },
            {
            url:require("@/assets/image/index/10201.jpg"),
            t1:"互隆设备小制冷售卖机",
            t2:"大型触屏 超大组合 智能零售"
            },
            {
            url:require("@/assets/image/index/10201.jpg"),
            t1:"互隆设备小制冷售卖机",
            t2:"大型触屏 超大组合 智能零售"
            },
            {
            url:require("@/assets/image/index/10201.jpg"),
            t1:"互隆设备小制冷售卖机",
            t2:"大型触屏 超大组合 智能零售"
            }
        ]
    }
]

/**
 * @产品展示数据
 */
 export const state05 = [
    {// 第1页
        t:[  // 上面四个
            {
            url:require("@/assets/image/index/1A01.jpg"),
            t1:"售货机 AI识别 先取后付",
            t2:"大型触屏 超大组合 智能零售",
            d:{
                url:require("@/assets/image/index/1A01.jpg"),
                name:'互隆智能触屏大制冷自助售卖机',
                tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                pp:'互隆',
                cc:'尺寸',
                gn:'功能',
                zl:'重量',
                gl:'功率',
                qhk:'取货口',
                fbl:'分辨率',
                zlfw:'制冷范围',
                jg:'面议',
                zffs:'？'
            }
            },
            {
            url:require("@/assets/image/index/10201.jpg"),
            t1:"智能售卖机 制冷",
            t2:"大型触屏 超大组合 智能零售",
            d:{
                url:require("@/assets/image/index/1A02.jpg"),
                name:'互隆智能触屏大制冷自助售卖机',
                tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                pp:'互隆',
                cc:'尺寸',
                gn:'功能',
                zl:'重量',
                gl:'功率',
                qhk:'取货口',
                fbl:'分辨率',
                zlfw:'制冷范围',
                jg:'面议',
                zffs:'？'
            }
            },
            {
            url:require("@/assets/image/index/1A03.png"),
            t1:"自助售票机 体福共用",
            t2:"大型触屏 超大组合 智能零售",
            d:{
                url:require("@/assets/image/index/1A03.png"),
                name:'互隆智能触屏大制冷自助售卖机',
                tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                pp:'互隆',
                cc:'尺寸',
                gn:'功能',
                zl:'重量',
                gl:'功率',
                qhk:'取货口',
                fbl:'分辨率',
                zlfw:'制冷范围',
                jg:'面议',
                zffs:'？'
            }
            },
            {
            url:require("@/assets/image/index/2A02.png"),
            t1:"壁挂零售机 可落地",
            t2:"大型触屏 超大组合 智能零售",
            d:{
                url:require("@/assets/image/index/2A02.png"),
                name:'互隆智能触屏大制冷自助售卖机',
                tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                pp:'互隆',
                cc:'尺寸',
                gn:'功能',
                zl:'重量',
                gl:'功率',
                qhk:'取货口',
                fbl:'分辨率',
                zlfw:'制冷范围',
                jg:'面议',
                zffs:'？'
            }
            },
            {
            url:require("@/assets/image/index/1A02.jpg"),
            t1:"售货机 小型 桌面款",
            t2:"大型触屏 超大组合 智能零售",
            d:{
                url:require("@/assets/image/index/1A02.jpg"),
                name:'互隆智能触屏大制冷自助售卖机',
                tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                pp:'互隆',
                cc:'尺寸',
                gn:'功能',
                zl:'重量',
                gl:'功率',
                qhk:'取货口',
                fbl:'分辨率',
                zlfw:'制冷范围',
                jg:'面议',
                zffs:'？'
            }
            }
        ],
        b:[  // 下面四个
            {
            url:require("@/assets/image/index/10201.jpg"),
            t1:"互隆设备小制冷售卖机",
            t2:"大型触屏 超大组合 智能零售",
            d:{
                url:require("@/assets/image/index/10201.jpg"),
                name:'互隆智能触屏大制冷自助售卖机',
                tip:'自定义货道 自寻系统 工艺与人性化结合 更懂你的多变智能售卖机',
                pp:'互隆',
                cc:'尺寸',
                gn:'功能',
                zl:'重量',
                gl:'功率',
                qhk:'取货口',
                fbl:'分辨率',
                zlfw:'制冷范围',
                jg:'面议',
                zffs:'？'
            }
            },
            {
            url:require("@/assets/image/index/10201.jpg"),
            t1:"互隆设备小制冷售卖机",
            t2:"大型触屏 超大组合 智能零售"
            },
            {
            url:require("@/assets/image/index/10201.jpg"),
            t1:"互隆设备小制冷售卖机",
            t2:"大型触屏 超大组合 智能零售"
            }
        ]
    }
]
