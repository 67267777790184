<template>
  <transition>
  <router-view/>
  </transition>
</template>
<script>
import Store from './store'
import { testState } from './store/state' 
import { defineComponent, provide , readonly } from "vue"
export default defineComponent({
  setup() {
    provide(Store.testStore, readonly({testState}))
  }
})
</script>
<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color #2c3e50
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td 
  margin: 0 
  padding: 0 

</style>
