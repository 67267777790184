<template>
  <section id="About">
    <article class="title">
      <!-- <cite>
        <span style="color: blue">About us</span>
        <span style="text-indent: 0.1rem; display: inline-block">关于我们</span>
      </cite> -->
      <!-- <hr /> -->
      <span style="font-size:22px;color: red">关于我们</span>
      <div  style="font-size:15px;color: rgb(49, 40, 40);line-height: 30px;">互隆科技有限公司成立于2014年，立足于美丽的海滨城市厦门市，主要从事软件平台开发，智能硬件设备设计生产和销售工作，
        致力于为用户提供软硬件一体化的解决方案。公司成立多年来，深耕餐饮行业、智能零售行业以及物联网行业的产品研发，积极广泛收集用户反馈，打磨出了餐饮Saas方案、智能零售方案和IoT物联网方案。
        </div>
      <div style="width:100%;margin-top: 20px">
        <div style="display: flex">
          <img src="../assets/image/about/about.jpg" style="width:50%;" alt="">
          <div style="width:50%;padding: 30px 20px;background: rgb(51, 51, 51);color: white;margin: 0 auto;display: flex;flex-direction: column;justify-content: center;">
            <div style="font-size: 20px">软件平台研发，硬件产品设计</div>

            <div style="font-size: 13px;margin-top: 20px;line-height: 30px;">公司遵循“诚信、共享、合作、双赢的发展理念，坚持”以服务、信誉第一“的经营宗旨，竭诚为客户提供优质的产品，以诚信同荣的饱满精神提供给用户意的产品。</div>
          </div>
        </div>
      </div>
      <div style="width:100%;display: flex;background: red;height: 200px;font-size: 13px;">
        <div style="width: 25%;display:flex;flex-direction:column;align-items:center;justify-content:center;border-right: solid 1px rgb(255, 89, 89);color: white;">
          <img src="../assets/image/about/about_1.png" width="55" height="50" />
          <span style="margin-top: 20px">20年技术积累</span>
        </div>
        <div style="width: 25%;display:flex;flex-direction:column;align-items:center;justify-content:center;border-right: solid 1px rgb(255, 89, 89);color: white;">
          <img src="../assets/image/about/about_2.png" width="55" height="50" />
          <span style="margin-top: 20px">国家高新企业</span>
        </div>
        <div style="width: 50%;display:flex;flex-direction:column;align-items:center;justify-content:center;color: white;">
          <img src="../assets/image/about/about_3.png" width="55" height="50" />
          <span style="margin-top: 20px">我们多年的生产经验，带给您产品和服务，让您用的放心</span>
        </div>
      </div>
    </article>
    <!-- <article class="container">

    </article> -->

    <!-- <article class="title">
      <cite>
        <span style="color: blue">Display</span>
        <span style="text-indent: 0.1rem; display: inline-block">工厂展示</span>
      </cite>
      <hr />
      <dfn
        >互隆已成为全国500万用户提供产品解决方案，业务覆盖一二线城市，用户达30000台，实现营收突破19.5亿。</dfn
      >
    </article>
    <article class="container">

    </article> -->

    <!-- <article class="title">
      <cite>
        <span style="color: blue">Honor</span>
        <span style="text-indent: 0.1rem; display: inline-block">荣誉&资质</span>
      </cite>
      <hr />
      <dfn
        >互隆已成为全国500万用户提供产品解决方案，业务覆盖一二线城市，用户达30000台，实现营收突破19.5亿。</dfn
      >
    </article>
    <article class="container">
      
    </article> -->
  </section>
</template>

<script>
export default {};
</script>

<style lang='stylus' scoped>
#About {
  width: 100%;
  padding: 0 2.5rem;
  background: #fff;
}
.title {
  font-size: 0.14rem;
  padding-bottom: 0.3rem

  cite {
    padding-top: 0.3rem;
    display: block;
    font-style normal
  }

  hr {
    border: none;
    height: 0.01rem;
    transform: scaleY(1.3);
    background-color: #999;
    opacity: 0.6;
    width: 1rem;
    position: relative;
  }

  hr:after {
    content: '';
    position: absolute;
    width: 0.24rem;
    height: 0.04rem;
    background: blue;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  dfn {
    font-size: 12px;
    display: block;
    padding-top: 0.06rem;
    max-width 60vw
    line-height 200%
    margin 0 auto
    font-style normal
    padding-bottom 30px
  }
}

.container
  height 2rem
  background #999
  margin .2rem 1rem
</style>