<template>
  <section id="ProfessionInfo">
    <div style="display: flex;width: 100%;padding-bottom: 50px;">
      <div style="width:36%">
        <div style="display:flex;justify-content:space-between;height: 30px;">
          <span style="font-size:16px;color:red;font-weight:bold">行业资讯</span>
          <span style="font-size:14px;color:red">MORE</span>
        </div>
        <div class="info" >
          <span>物联网应用不断拓展广度深度</span>
          <span>2023-09-20</span>
        </div>
        <div class="info" >
          <span>物联网赋能制造业生产</span>
          <span>2023-8-16</span>
        </div>
        <div class="info" >
          <span>万物相连 今年我国物联网连接数预计将超23亿</span>
          <span>2023-04-20</span>
        </div>
        <div class="info" >
          <span>工信部：我国移动物联网连接数全球占比超70%</span>
          <span>2022-09-13</span>
        </div>
        <div class="info" >
          <span>电力物联网加速创新 数据互联互通释放强劲动能</span>
          <span>2022-04-11</span>
        </div>
      </div>
      <div style="display:flex;padding-left:60px;padding-top: 40px;align-items: center;width: 62%;">
        <img src="../assets/image/about/info_1.jpg" width="240" height="290" alt="">
        <div style="padding: 30px 0 30px 30px;">
          <div style="font-weight:bold;font-size:14px">物联网应用不断拓展广度深度</div>
          <div style="font-size:12px;margin-top: 20px;">物联网是战略性新兴产业的重要组成部分。近年来，我们大力推进新型基础设施建设，以智能制造为主攻方向，推动我国物联网蓬勃发展。</div>
          <button class="look"  @click="lookdetail">查看详情</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup(){
    const lookdetail = function(){
      console.log("lookdetail")
    }
    return {
      lookdetail
    }
  }
}
</script>

<!-- height 10rem -->
<style lang='stylus' scoped>

#ProfessionInfo
  width 100%
  padding: 0 2.5rem
  font-size: 12px

.info
  display:flex
  justify-content:space-between
  height: 60px
  align-items: center 
.info:hover
  cursor pointer
.look
  margin-top: 40px
  border: solid 1px red
  padding: 10px 20px
  color: red
  background: white
.look:hover
  cursor pointer
</style>