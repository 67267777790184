<template>
  <section id="Swiper">
    <div style="padding: 0 2.5rem;display:flex;width: 100%;justify-content: flex-start;">
      <div style="width:220px;height: 350px;background: #f5f5f5;position: sticky;">
        <div @click="$emit('E_product',item)" v-for="(item,index) in tabs" :key="index"  class="product">{{item.text}}</div>
        <!-- <div class="product">智能售货机</div>
        <div class="product">多功能自助机</div>
        <div class="product">物联网设备</div>
        <div class="product">格子柜</div>
        <div class="product">取餐机</div>
        <div class="product">广告机</div>
        <div class="product">消费类电子</div> -->
      </div>
      <div style="margin-top: 10px;width: 100%;margin-left: 10px;overflow: hidden;">
        <a-carousel autoplay >
          <img style="width:100%;" src="../assets/image/index/10101.png" alt="暂无图片">
          <img style="width:100%;" src="../assets/image/index/10102.png" alt="暂无图片">
        </a-carousel>
        <!-- <a-carousel autoplay dots-class="slick-dots slick-thumb">
      <article v-for="item in list" :key="item">
        <img src="../assets/image/index/10101.jpg" alt="暂无图片">
      </article>
    </a-carousel> -->
      </div>

    </div>
  </section>
</template>
<script>
import { onMounted, reactive, toRefs } from 'vue';
import { state01 } from '../assets/data/index.js'
export default {
  setup() {
    const state = reactive({
      list: state01,
      tabs:[
        {
          text:"智能售货机",
          index:"1"
        },
        {
          text:"多功能自助机",
          index:"2"
        },
        {
          text:"物联网设备",
          index:"3"
        },
        {
          text:"格子柜",
          index:"4"
        },
        {
          text:"取餐机",
          index:"5"
        },
        {
          text:"广告机",
          index:"6"
        },
        {
          text:"消费类电子",
          index:"7"
        }
      ],
    })
    onMounted(async () => {
      console.log(state.list);
    })
    
    return {
      ...toRefs(state),
    }
  }
}
</script>
<style lang="stylus" scoped>
#Swiper{
  
}

.product
  color: black
  height: 50px
  display: flex
  align-items: center
  padding-left: 10px
  font-size: 12px
  width: 220px
.product:hover
  color: red
  background: white
  cursor: pointer
  border: 1px solid red

:deep(.slick-slide) {
    text-align: center;
    height: 350px;
    line-height: 350px;
    background: #364d79;
    overflow: hidden;
  }
  
:deep(.slick-slide h3) {
    color: #fff;
  }
</style>
