<template>
  <section id="PrdouctsNew">
      <!-- <article class="title">
          <cite>
              <span style="color:blue">Prdoucts</span>
              <span style="text-indent:.1rem;display:inline-block">产品展示</span>
          </cite>
          <hr />
          <dfn>互隆——专注于为全球客户提供高品质自动售货机产品。</dfn>
      </article> -->
      <div style="display: flex;margin-top: 30px;margin-bottom: 30px;">
        <div @click="showOne" class="hotpro" id="sugpro" style="background: red;color: white;">推荐产品</div>
        <div @click="showTwo" class="hotpro" id="hotpro" >热销产品</div>
      </div>
      <div v-for="item in list" :key="item">
        <article class="body">
              <figure v-for="i in item.t" :key="i">
                  <img class="pic" :src="i.url" alt="" @click="$emit('E_detail',i)">
                  <!-- <img class="pic" src="../assets/image/index/10201.jpg" alt=""> -->
                  <!-- <div class="mask">
                      <button @click="$emit('E_detail',i)">查看详情</button>
                  </div> -->
                  <figcaption style="line-height:200%;padding-top:.08rem">{{i.t1}}</figcaption>
                  <!-- <figcaption style="transform scale(.8)">{{i.t2}}</figcaption> -->
              </figure>
              </article>
      </div>
      <div>

      </div>
  </section>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity'
import { state02,state04} from '../assets/data/index.js'
export default {
  setup(){
    const state = reactive({
      list:state02,
    })
    console.log(state.list)
    const showOne = function(){
      state.list=state02
      var h = document.getElementById('hotpro');
      h.style.color = "black"
      h.style.background = "white"
      var s = document.getElementById('sugpro');
      s.style.color = "white"
      s.style.background = "red"
    }
    const showTwo = function(){
      state.list=state04;
      var h = document.getElementById('hotpro');
      h.style.color = "white"
      h.style.background = "red"
      var s = document.getElementById('sugpro');
      s.style.color = "black"
      s.style.background = "white"
    }
    return {
      ...toRefs(state),
      showOne,
      showTwo
    }
  }
}
</script>
.
<style scoped lang="stylus">
#PrdouctsNew
  background #fff
  padding: 0 2.5rem
.title
  text-align center
  font-size .14rem
  cite 
    padding-top .3rem
    display block
    font-style normal
  hr 
    border: none;
    height: .01rem;
    transform scaleY(1.3)
    background-color: #999;
    opacity .6
    width 1rem
    position relative
  hr:after
    content ""
    position absolute
    width .24rem
    height .04rem
    background blue
    top 50%
    left 50%
    transform translate(-50%,-50%)
  dfn
    font-size .1rem
    display block
    padding-top .06rem
    font-style normal
.body
  display flex
  flex-wrap nowrap
  justify-content space-between
  figure
    text-align center
    font-size 14px
    position relative
    
    .pic
      height 180px
      width 180px
    .pic:hover
      transform: translateX(-10px);
.hotpro
  width:140px
  justify-content: center
  height: 40px
  display: flex
  align-items: center
.hotpro:hover
  cursor pointer
</style>
