import {ref, onMounted , onUnmounted} from 'vue'
// [Header,Prdoucts,About,Classic,Partner,Phone]

export default function () {
    const RollTo = function(DomId){
        document.documentElement.scrollTop = document.getElementById(DomId).offsetTop - document.getElementById("Header").clientHeight
    }
    const HeaderCurrentTab = ref("Header")
    const ScrollSwitch = ()=>{
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        let S2 = document.getElementById("PrdouctsNew").offsetTop - document.getElementById("Header").clientHeight -1
        // let S3 = document.getElementById("Classic").offsetTop - document.getElementById("Header").clientHeight -1
        // let S4 = document.getElementById("Partner").offsetTop - document.getElementById("Header").clientHeight -1
        let S5 = document.getElementById("About").offsetTop - document.getElementById("Header").clientHeight -1
        let S6 = document.getElementById("ProfessionInfo").offsetTop - document.getElementById("Header").clientHeight -1
        let S7 = document.getElementById("FooterNew").offsetTop - document.getElementById("Header").clientHeight -1

        if(scrollTop <= S2){
            HeaderCurrentTab.value = "Header" 
        }
        if(scrollTop > S2 && scrollTop <= S5){
            HeaderCurrentTab.value = "PrdouctsNew" 
        }
        if(scrollTop > S5 && scrollTop <= S6){
            HeaderCurrentTab.value = "About" 
        }
        if(scrollTop > S6 && scrollTop <= S7){
            HeaderCurrentTab.value = "ProfessionInfo" 
        }
        if(scrollTop > S7 ){
            HeaderCurrentTab.value = "FooterNew" 
        }
    }
    onMounted(()=>{
        document.addEventListener("scroll",ScrollSwitch);
    })
    onUnmounted(()=>{
        document.removeEventListener("scroll",ScrollSwitch);
    })
    return {
        RollTo,
        HeaderCurrentTab,
    }
}