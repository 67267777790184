<template>
  <section id="FooterNew">
    <div>
      <div>联系我们</div>
      <div>400-8381-708</div>
      <div>厦门市翔安区翔虹路18号二楼</div>
    </div>
    <div>
      <div>联系QQ：10941782</div>
      <div>手机号码：13400611359</div>
      <div>联系邮箱：lhj@wholler.com</div>
    </div>
    <div>
      <div>@2024-互隆科技 版权所有</div>
      <div>闽ICP备18018581号</div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FooterNew',
  props: {
    // msg: Number
  },
  setup(){
    return {
    }
  }
}
</script>

<style lang='stylus' scoped>
#FooterNew
  width 100%
  background #000
  padding: 0 2.5rem;
  display flex
  align-items center
  justify-content space-between
  height: 200px
  color: white

</style>